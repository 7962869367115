$(document).ready(() => {
  $('#congresses_users_register_professional_group, #congresses_users_insert_professional_group, #user_professional_group_id').change(function(e) {
    var professional_group = $(this).children('option:selected').val()
    var groups = []
    $(this).find('option').each(function(){
      groups[$(this).html()] = $(this).val()
    })
    const doctor = groups['Lekarz'], pharmacist = groups['Farmaceuta'], nurse = groups['Pielęgniarka / Położna'],
          student = groups['Student'], representant = groups['Reprezentant'], technican = groups['Technik farmaceutyczny'],
          dental_technician = groups['Technik dentystyczny'], dental_hygienist = groups['Higienista stomatologiczny'],
          optometrist = groups['Optometrysta'], orthoptist = groups['Ortoptysta']

    if (professional_group == doctor) {
      $('.block_specializations').show()
    } else {
      $('.block_specializations').hide()
    }

    if ( [doctor, nurse, pharmacist, optometrist, orthoptist].includes(professional_group) ){
      $('.block_npwz').show()
    } else {
      $('.block_npwz').hide()
    }

    if (professional_group == student) {
      $('.block_student').show()
    } else {
      $('.block_student').hide()
    }

    if ([representant, dental_technician, dental_hygienist].includes(professional_group)) {
      $('.block_company').show()
    } else {
      $('.block_company').hide()
    }

    if (professional_group == technican) {
      $('.block_technican').show()
    } else {
      $('.block_technican').hide()
    }
  });
});
